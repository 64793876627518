import * as React from 'react';
import {
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Button
} from '@mui/material';
import Tag from './Tag';

const WorkCard = ({
  thumb,
  title,
  description,
  link,
  repo,
  tags
}) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <a href={link} target="_blank" rel="noreferrer noopener">
        <CardMedia
          component="img"
          height="194"
          image={`${thumb}`}
          alt={title}
          className="workThumb"
        />
      </a>
      <CardHeader
        title={title}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <div className="tags">
          {tags.map((tag, i) => <Tag key={i} tag={tag} />)}
        </div>
      </CardContent>
      <CardActions className="cardAction">
        <Button
          size="small"
          className="cardActionLink"
          target="_blank"
          rel="noreferrer noopener"
          href={repo}
        >
          Repo
        </Button>
        <Button
          size="small"
          className="cardActionLink"
          target="_blank"
          rel="noreferrer noopener"
          href={link}
        >
          Project Link
        </Button>
      </CardActions>
    </Card>
  )
}

export default WorkCard;
