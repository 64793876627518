import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FadeInSection from '../components/FadeInSection';

const About = () => {
  return (
    <section className="section" id="about">
      <div id="about-section" className="sectionAnchor"></div>
      <Container>
        <FadeInSection>
          <Typography variant="h2" className="sectionHeading">
            About
          </Typography>
        </FadeInSection>
        <FadeInSection>
          <div>
            <p>
              I love helping people make their dreams come true and being part of people's success stories. That is my dream and that would be my story.
            </p>
            <p>
              I have a passion for software development and I wish to learn many things until I find my niche in this field. I want to start building useful products that would help to make this world a better place.
            </p>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div>
            <Typography variant="h3" className="sectionSubheading">
              Other Interests
            </Typography>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4" className="accordionHeading">Exploring Japan</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  I love Japanese culture and I want to explore and experience Japan more. I spend a great amount of time each time planning and building itineraries for Japan holidays (complete with weather forecasts and outfits to wear).
                </Typography>
                <Typography>
                  Some of my favourite places that I've been so far are Yakushima (an island in the south with a beautiful forest that inspired the woodlands in Princess Mononoke), Shima Onsen (a quiet hot spring town in Gunma), Shiretoko (if you want to go snowshoeing there, I know the best tour guide!), Kisoji (Tsumago and Magome are a bit touristy but the Nakasendo trail had been a wonderful experience), Amanohasidate (a romantic place that I want to re-visit again someday).
                </Typography>
                <Typography>
                  I started to learn Japanese, but need more motivation to progress! My WaniKani reviews are hitting three digits already! I'm thinking of taking a language course, but too shy to speak...
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h4" className="accordionHeading">FFXIV MMORPG</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  I play Final Fantasy XIV MMORPG casually in my spare time (which I don't have, but I create one, occasionally), and I'm one of FinFan followers.
                </Typography>
                <Typography>
                  Still a sprout, but trying hard not to let the party down. My main class is Dragoon, but I like to play safe with Machinist. I might not be an efficient player but I hit the buttons fast and hard (not that it helps to deal more DPS!).
                </Typography>
                <Typography>
                  I play in Unicorn server, dressed in Moogle-themed attire. Yes, I love Moogles. Very. Much. The English translation scripts are more interesting than the Japanese ones for the Moogle beast tribe quests somehow.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography variant="h4" className="accordionHeading">Cooking</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  I like to cook, although I don't have the talent! *laughs then cries* I just love gathering the ingredients, chopping, mixing, measuring things, experimenting with recipes, and crafting some avant-garde meals.
                </Typography>
                <Typography>
                  I spend time thinking and planning for meal preps, browsing through supermarket catalogues to get the best deal, and then creating a-week long meal plan strictly based on the savings (don't judge me, please). I do, however, wish that I could eat out more often *wallet cries*.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </FadeInSection>
      </Container>
    </section>
  )
}

export default About;
