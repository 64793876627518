const Footer = () => {
  const getYear = () => {
      const currentYear = new Date().getFullYear();
      return currentYear;
  };
  return (
    <footer>
      <div>&copy; {getYear()} Jessica Gozali</div>
    </footer>
  )
}

export default Footer;
