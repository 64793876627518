import { Container, Typography, Grid } from "@mui/material";
import FadeInSection from '../components/FadeInSection';
import SkillItem from "../components/SkillItem";

const Skills = ({ skills }) => {
  return (
    <section className="section" id="skills">
      <div id="skills-section" className="sectionAnchor"></div>
      <Container>
        <FadeInSection>
          <Typography variant="h2" className="sectionHeading">
            Skills
          </Typography>
        </FadeInSection>
        <Grid container spacing={2} className="skillList">
        {skills.map((skill, i) => (
          <Grid item xs={4} md={3} lg={2} key={i}>
            <FadeInSection>
              <SkillItem skillName={skill.skillName} skillIcon={skill.skillIcon} />
            </FadeInSection>
          </Grid>
        ))}
        </Grid>
      </Container>
    </section>
  )
}

export default Skills;
