import {
  Container,
  Typography,
  Avatar,
  FormControl,
  TextField,
  Button
} from "@mui/material";
import FadeInSection from '../components/FadeInSection';

const Contact = ({ accountIcons }) => {
  return (
    <section className="section" id="contact">
      <div id="contact-section" className="sectionAnchor"></div>
      <Container>
        <FadeInSection>
          <Typography variant="h2" className="sectionHeading">
            Contact
          </Typography>
        </FadeInSection>
        <FadeInSection>
          <div>
            <Typography variant="h3" className="sectionSubheading">
              Account Links
            </Typography>
            <p>
              You can find me on LinkedIn, or check out my current projects in Bitbucket or GitHub or some practice code in freeCodeCamp.
            </p>
            <div className="accountList">
              {accountIcons.map((acc, i) => (
                <div key={i} className="accountLink">
                  <a
                      className="accTagLink"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={acc.url}
                    >
                    <Avatar alt={acc.accName} src={acc.svg} />
                    <Typography variant="h4" className="accountLabel">
                        {acc.accName}
                    </Typography>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </FadeInSection>
        <FadeInSection>
          <div>
            <Typography variant="h3" className="sectionSubheading">
              Contact Form
            </Typography>
            <p>Or, feel free to send me a message.</p>
            <form name="contact" method="post" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <FormControl variant="standard" sx={{ minWidth: 250 }}>
                <TextField
                  className="formTextField"
                  required
                  id="name"
                  name="name"
                  label="Name"
                  variant="standard"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  className="formTextField"
                  required
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  className="formTextField"
                  required
                  id="message"
                  name="message"
                  label="Message"
                  variant="standard"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                />
                <Button
                  type="submit"
                  variant="contained"
                  margin="normal"
                >
                  Submit
                </Button>
              </FormControl>
            </form>
          </div>
        </FadeInSection>
      </Container>
    </section>
  )
}

export default Contact;
