import React, { useRef, useState, useEffect } from 'react';

const FadeInSection = ({ children }) => {
  const domRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
      
        // Not possible to set it back to false like this:
        setVisible(true);
        
        // No need to keep observing:
        observer.unobserve(domRef.current);
      }
    });

    observer.observe(domRef.current);
    
    // return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <section ref={ domRef } className={ "fade-in" + (visible ? ' is-visible' : '') }>{ children }</section>
  )
}

export default FadeInSection;
