import { Paper, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  height: 150,
  lineHeight: '150px',
}));

const SkillItem = ({ skillName, skillIcon }) => {
  return (
    <Item elevation={1} className="skillIcon">
      <Avatar alt={skillName} src={skillIcon} variant="square" sx={{ width: 64, height: 64 }} />
      <Typography variant="h6">{skillName}</Typography>
    </Item>
  )
}

export default SkillItem;
