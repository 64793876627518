import './globalStyling.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from './components/Header';
import Footer from './components/Footer';
import TopHeading from './sections/TopHeading';
import Works from './sections/Works';
import Skills from './sections/Skills';
import About from './sections/About';
import Contact from './sections/Contact';

// For website theme
const theme = createTheme({
  palette: {
    primary: {
    main: '#F5DAE1',
    light: '#F6EFEF',
    dark: '#E50079',
    },
    secondary: {
    main: '#F2F0EF',
    light: '#F8F8F8',
    dark: '#353A4A',
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif',
    color: '#353A4A',
  },
});


// List of works
const works = [
  {
    title: 'Bat and Bun',
    thumb: './imgs/bat-and-bun-thumb.jpg',
    link: 'https://bat-and-bun.herokuapp.com/',
    repo: 'https://github.com/petitejess/Bat_and_Bun_restaurant',
    path: '/batandbun',
    description: 'A simple MERN stack eCommerce app for Bat and Bun restaurant with Firebase Auth, simple Stripe payment and image upload to AWS S3.',
    tags: ['MongoDB', 'Express JS', 'React JS', 'Node JS', 'Material UI', 'SASS', 'Firebase', 'Stripe', 'AWS S3', 'eCommerce']
  },
  {
    title: 'Japanglish Dictionary',
    thumb: './imgs/japanglish-thumb.jpg',
    link: 'https://japanglish.jessicagozali.com.au/',
    repo: 'https://bitbucket.org/jessicagozali/japanglish-dictionary',
    path: '/japanglish',
    description: 'A micro dictionary web app that was created for my personal collections of Japanese words from English adaptation.',
    tags: ['React JS', 'Material UI', 'SASS', 'Firebase', 'Rails API', 'NoSQL']
  },
  {
    title: 'Pro Cleaner Finder',
    thumb: './imgs/pro-cleaner-finder-thumb.jpg',
    link: 'https://pro-cleaner-finder.herokuapp.com/',
    repo: 'https://github.com/petitejess/pro-cleaner-finder',
    path: '/pro-cleaner-finder',
    description: 'A prototype of a two-way marketplace for people to find professional cleaning services, with a simple quote, booking, and Stripe API payment gateway integration.',
    tags: ['Ruby on Rails', 'Two-Way Marketplace', 'Bootstrap', 'SASS', 'PostgreSQL', 'Amazon S3', 'Stripe']
  },
  {
    title: 'Kids Translator Cards',
    thumb: './imgs/kids-translator-cards-thumb.jpg',
    link: 'https://kids-translator-cards.netlify.app/',
    repo: 'https://github.com/petitejess/kids-translator-cards/',
    path: '/kids-translator-cards',
    description: 'A family-friendly kids translation web app group project for Coder Academy React Hackathon Oct 2021.',
    tags: ['React JS', 'Material UI', 'SASS', 'Google Translator API', 'REST Countries API', 'Pixabay API', 'Bad Words Filter', 'Hackathon', 'Collaboration']
  },
  {
    title: 'A-Z Quiz',
    thumb: './imgs/azquiz-thumb.jpg',
    link: 'https://azquiz.jessicagozali.com.au/',
    repo: 'https://bitbucket.org/jessicagozali/a-z-quiz/',
    path: '/azquiz',
    description: 'A quiz web app where players need to write down words starting with a certain letter within certain categories.',
    tags: ['React JS', 'Material UI', 'API', 'SASS', 'Quiz']
  },
  {
    title: 'To Do Today',
    thumb: './imgs/to-do-today-thumb.jpg',
    link: 'https://todotoday-app.netlify.app/',
    repo: 'https://bitbucket.org/jessicagozali/to-do-today/',
    path: '/to-do-today',
    description: 'A simple to do list app built with React JS with extra weather information from API. Based on the weather condition, the app will give a caring message.',
    tags: ['React JS', 'API', 'SASS']
  },
  {
    title: 'Mood Booster',
    thumb: './imgs/mood-booster-thumb.jpg',
    link: 'https://the-mood-booster.netlify.app/',
    repo: 'https://github.com/petitejess/fx1_syd_mood_booster_hackathon',
    path: '/mood-booster',
    description: "A 24-hr group project for Coder Academy JavaScript Hackathon Sep 2021. A tiny app that shows random quotes based on users' moods",
    tags: ['Vanilla JS', 'Famous Quotes API', 'Hackathon', 'Collaboration']
  },
  {
    title: 'Burger Game',
    thumb: './imgs/burger-game-thumb.jpg',
    link: 'https://rubygems.org/gems/burger_game/',
    repo: 'https://github.com/petitejess/burger_game/',
    path: '/burger-game',
    description: 'A simple text-based Ruby terminal game that simulates a burger shop, where you need to build the meal for the customers',
    tags: ['Ruby', 'Ruby Gem', 'Terminal Game']
  },
  {
    title: 'My Life as a Coder',
    thumb: './imgs/my-life-as-a-coder-thumb.jpg',
    link: 'https://jessica.webtink.com.au/',
    repo: 'https://bitbucket.org/jessicagozali/my-life-as-a-coder/',
    path: '/my-life-as-a-coder',
    description: 'A website created for Coder Academy Diversity Scholarship program, showing a little story about life as a coder.',
    tags: ['HTML5 Boilerplate', 'CSS Animation', 'Phaser', 'Parallax']
  },
  {
    title: 'Zen Garden',
    thumb: './imgs/zen-garden-thumb.jpg',
    link: 'https://zen-garden.jessicagozali.com.au/',
    repo: 'https://github.com/petitejess/zen-garden-css-challenge/',
    path: '/zen-garden',
    description: 'A sample website for CSS Zen Garden Challenge.',
    tags: ['HTML5', 'CSS3']
  },
  {
    title: 'Sheila on 7',
    thumb: './imgs/sheila-on-7-thumb.jpg',
    link: 'https://sheila-on-7.jessicagozali.com.au/',
    repo: 'https://github.com/petitejess/band-challenge-multipage-website',
    path: '/sheila-on-7',
    description: 'Sample website for Coder Academy Ed activity, Band Challenge: Multipage Website.',
    tags: ['HTML5', 'CSS3']
  }
];

// List of skills
const skills = [
  {
    skillName: 'HTML5',
    skillIcon: './imgs/html5-icon.svg'
  },
  {
    skillName: 'CSS3',
    skillIcon: './imgs/css3-icon.svg'
  },
  {
    skillName: 'JavaScript',
    skillIcon: './imgs/javascript-icon.svg'
  },
  {
    skillName: 'React',
    skillIcon: './imgs/reactjs-icon.svg'
  },
  {
    skillName: 'Express',
    skillIcon: './imgs/expressjs-icon.svg'
  },
  {
    skillName: 'Node.js',
    skillIcon: './imgs/nodejs-icon.svg'
  },
  {
    skillName: 'Ruby',
    skillIcon: './imgs/ruby-icon.svg'
  },
  {
    skillName: 'Rails',
    skillIcon: './imgs/rails-icon.svg'
  },
  {
    skillName: 'SASS',
    skillIcon: './imgs/sass-icon.svg'
  },
  {
    skillName: 'Material UI',
    skillIcon: './imgs/mui-icon.svg'
  },
  {
    skillName: 'Bootstrap',
    skillIcon: './imgs/bootstrap-icon.svg'
  },
  {
    skillName: 'Stripe',
    skillIcon: './imgs/stripe-icon.svg'
  },
  {
    skillName: 'PostgreSQL',
    skillIcon: './imgs/postgresql-icon.svg'
  },
  {
    skillName: 'MongoDB',
    skillIcon: './imgs/mongodb-icon.svg'
  },
  {
    skillName: 'Amazon S3',
    skillIcon: './imgs/aws-s3-icon.svg'
  },
  {
    skillName: 'Bitbucket',
    skillIcon: './imgs/bitbucket-icon.svg'
  },
  {
    skillName: 'GitHub',
    skillIcon: './imgs/github-icon.svg'
  },
  {
    skillName: 'Jest',
    skillIcon: './imgs/jest-icon.svg'
  },
  {
    skillName: 'RSpec',
    skillIcon: './imgs/rspec-icon.svg'
  },
  {
    skillName: 'Postman',
    skillIcon: './imgs/postman-icon.svg'
  },
  {
    skillName: 'VS Code',
    skillIcon: './imgs/vs-code-icon.svg'
  },
  {
    skillName: 'Trello',
    skillIcon: './imgs/trello-icon.svg'
  },
  {
    skillName: 'Netlify',
    skillIcon: './imgs/netlify-icon.svg'
  },
  {
    skillName: 'Heroku',
    skillIcon: './imgs/heroku-icon.svg'
  }
];

// List of account icons
const accountIcons = [
  {
    accName: 'LinkedIn',
    svg: './imgs/linkedin-icon.svg',
    url: 'https://www.linkedin.com/in/jessicagozali/'
  },
  {
    accName: 'Bitbucket',
    svg: './imgs/bitbucket-acc-icon.svg',
    url: 'https://bitbucket.org/jessicagozali/'
  },
  {
    accName: 'GitHub',
    svg: './imgs/github-acc-icon.svg',
    url: 'https://github.com/petitejess'
  },
  {
    accName: 'fCC',
    svg: './imgs/fcc-icon.svg',
    url: 'https://www.freecodecamp.org/petitejess'
  },
];

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <main>
        <Router>
          <Switch>
            <Route exact path='/'>
              <TopHeading />
              <Works works={works} />
              <Skills skills={skills} />
              <About />
              <Contact accountIcons={accountIcons} />
            </Route>
          </Switch>
        </Router>
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export default App;
