import React from 'react';

const ParallaxBG = () => {
  return (
    <div className="parallaxBG" style={{ backgroundImage: "url('./imgs/top-section-bg.jpg')" }}>
    </div>
  )
}

export default ParallaxBG;
