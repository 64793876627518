import {
  AppBar,
  Toolbar,
  Typography,
  Box
} from '@mui/material';
import Navlinks from './Navlinks';

const Header = () => {
  return (
    <AppBar>
      <Toolbar className="navMenu">
        <Typography variant="h1" className="logoText">
          <a href='/'>Jessica Gozali</a>
        </Typography>
        <Box>
          <Navlinks />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header;
