import React from 'react';
import {
  Box,
  IconButton
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Menu items
const menuItemList = [
  {
    menuName: 'To Top',
    menuLink: '#top-section'
  },
  {
    menuName: 'Works',
    menuLink: '#works-section'
  },
  {
    menuName: 'Skills',
    menuLink: '#skills-section'
  },
  {
    menuName: 'About',
    menuLink: '#about-section'
  },
  {
    menuName: 'Contact',
    menuLink: '#contact-section'
  }
];

const Navlinks = () => {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 280 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <IconButton
        className="closeIcon"
        size="large"
        aria-label="close menu"
        color="inherit"
        onClick={toggleDrawer(anchor, true)}
      >
        <CloseIcon />
      </IconButton>
      <List>
        {menuItemList.map((menu, index) => (
          <ListItem
            button key={menu.menuLink}
            component="a"
            href={`${menu.menuLink}`}
          >
            <ListItemText primary={menu.menuName} disableTypography className="menuLink" />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="navigation menu"
            edge="end"
            color="inherit"
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

export default Navlinks;
