import Typography from '@mui/material/Typography';
import ParallaxBG from '../components/ParallaxBG';
import Bokeh from '../components/Bokeh';
import FadeInSection from '../components/FadeInSection';

const TopHeading = () => {
  return (
    // <section className="section" id="topHeading" style={{ backgroundImage: "url('./imgs/top-section-bg.jpg')" }}>
    <section className="section" id="topHeading">
      <div id="top-section" className="sectionAnchor"></div>
      <ParallaxBG />
      <Bokeh />
      <div className="textBg">
        <FadeInSection>
          <Typography variant="h2">
            Jessica Gozali
          </Typography>
        </FadeInSection>
        <FadeInSection>
          <Typography variant="h3" className="subtitle">
            Portfolio
          </Typography>
        </FadeInSection>
        <FadeInSection>
          <p>
            A collection of works showing a perfectly imperfect learning journey to become a web developer.
          </p>
        </FadeInSection>
      </div>
    </section>
  )
}

export default TopHeading;
