import { Container, Typography } from "@mui/material";
import FadeInSection from '../components/FadeInSection';
import Masonry from 'react-masonry-css';
import WorkCard from "../components/WorkCard";

const Works = ({ works }) => {
  const breakpoints = {
    default: 3,
    1200: 2,
    600: 1
  };

  return (
    <section className="section" id="works">
      <div id="works-section" className="sectionAnchor"></div>
      <Container>
        <FadeInSection>
          <Typography variant="h2" className="sectionHeading">
            Works
          </Typography>
        </FadeInSection>
        <Masonry
          container="true"
          breakpointCols={breakpoints}
          spacing={3}
          className="collections-masonry-grid"
          columnClassName="collections-masonry-grid-column"
        >
          {works.map((work, i) => (
            <FadeInSection key={i}>
              <WorkCard
                thumb={work.thumb}
                title={work.title}
                description={work.description}
                link={work.link}
                repo={work.repo}
                tags={work.tags}
              />
            </FadeInSection>
          ))}
        </Masonry>
      </Container>
    </section>
  )
}

export default Works;
